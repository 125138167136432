import React from "react";
import { Button, ButtonAlt } from "@atoms";

const ButtonGroup = ({ links }) => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-6">
      {links.map(l => {
        if (l.style) {
          return (
            <Button key={l.url} to={l.url}>
              {l.text}
            </Button>
          );
        }
        return (
          <ButtonAlt color="carnation" key={l.url} to={l.url}>
            {l.text} test
          </ButtonAlt>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
